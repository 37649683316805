<template>
  <div class="p-1">
    <div-table
      :config="configList"
      :data="payements"
      :element="element"
      @reset="reset"
      @refresh="refresh"
      @showProducts="showOrders"
      @filtrer="filtrer"
      @searchSellers="searchSellers"
      @search="search"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
      :idWharhouse="idWharhouse"
      :currentUser="currentUser"
      @selectAllCheckbox="selectAllCheckbox"
      @getRowDetail="getRowDetail"
      :tabId="tabId"
    ></div-table>
  </div>
</template>

<script>
import config from "./config";
export default {
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      configList: config.list,
      loading: false,
      payements: [],
      element: {},
      sellers: [],
      tabId: [],
      show_modal: false,
      limit: 100,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
      methods: ["transfer", "paypal", "cash"],
      // currentUser: {},
      // idWharhouse:null,
    };
  },
  async mounted() {
    // await this.getUser();
    // await this.getwharhouseId();
    await this.getPayement({ limit: this.limit });
    if (this.currentUser.type != "Seller") {
      await this.getSellers();
      this.setDataSelect("seller", this.sellers, null);
    }

    this.setDataSelect("method", this.methods, null);
    if (this.currentUser.type == "Seller") {
      let pos = this.configList.columns
        .map(function (e) {
          return e.name;
        })
        .indexOf("Seller");
      if (pos != -1) this.configList.columns.splice(pos, 1);
    }
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      // await this.getwharhouseId();
      await this.getPayement({ limit: this.limit });
      if (this.currentUser.type != "Seller") {
        await this.getSellers();
        this.setDataSelect("seller", this.sellers, null);
      }
    },
  },
  methods: {
    async getwharhouseId() {
      await this.$store
        .dispatch("wharhouse/getwharhouseId", {
          country: await this.warhouseSelected,
        })
        .then((res) => {
          this.idWharhouse = res;
        });
    },
    selectAllCheckbox($event) {
      let columns = document.querySelectorAll(".rowCheckbox");
      this.tabId = [];
      if ($event.target.checked == true) {
        columns.forEach((column) => {
          column.checked = true;
          this.tabId.push(column.id);
        });
      } else {
        columns.forEach((column) => {
          column.checked = false;
        });
        this.tabId = [];
      }
    },
    getRowDetail(item, id) {
      // let rows = this.selectedRows;
      let itemInRow = this.tabId.filter((item) => item === id);
      let isItemInRow = itemInRow.length > 0;

      if (isItemInRow) {
        let pos = this.tabId
          .map(function (e) {
            return e;
          })
          .indexOf(id);
        this.tabId.splice(pos, 1);
      } else {
        this.tabId.push(id);
      }
      if (this.tabId.length === 0) {
        let columns = document.querySelectorAll(".checkallbbox");
        columns.forEach((column) => {
          column.checked = false;
        });
      }
    },
    setDataSelect(model, data, splice) {
      for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
        if (
          this.configList.filters.elements.champs[i].type === "select" &&
          this.configList.filters.elements.champs[i].key === model
        ) {
          if (!splice) {
            this.configList.filters.elements.champs[i].values = data;
          } else {
            this.configList.filters.elements.champs.splice(i, 1);
          }
        }
      }
    },
    async getSellers() {
      const filter = {
        type: "Seller",
        countries: await this.warhouseSelected,
        limit: this.limit,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    },
    searchSellers: _.debounce(async function (search, event) {
      const filter = {
        type: "Seller",
        limit: this.limit,
        countries: await this.warhouseSelected,
        name: search,
      };
      const res = await this.$server.find("users", filter);

      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
      this.setDataSelect("seller", this.sellers, null);
    }, 500),
    async reset(data) {
      const filters = {
        limit: this.limit,
        country: await this.warhouseSelected,
      };
      await this.getPayement(filters);
    },
    async filtrer(data) {
      const filters = {
        method: data.method,
        date: data.date,
        limit: this.limit,
        country: await this.warhouseSelected,
      };
      if (data.seller) filters.seller = data.seller._id;
      await this.getPayement(filters);
    },
    /**********************************User and Status******************************************************/
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },
    async refresh() {
      await this.getPayement({ limit: this.limit });
    },

    showOrders(index) {
      if (this.element !== this.payements[index]) {
        this.element = this.payements[index];
        //
      } else this.element = null;
    },
    sumObjAmountCurrency(objArr) {
      var newArr = [];
      // loop over the objects in the array
      objArr.forEach(function (obj, ind, arr) {
        if (ind === arr.length - 1 || obj.currency !== arr[ind + 1].currency) {
          newArr.push(obj);
        } else {
          arr[ind + 1].amount += obj.amount;
          arr[ind + 1].count += obj.count;
          arr[ind + 1].fees += obj.fees;
          arr[ind + 1].totalPrice += obj.totalPrice;
        }
      });
      // return the result
      return newArr;
    },
    async getPayement(filters) {
      this.loading = true;
      if (this.currentUser.type == "Seller") {
        filters.seller = this.currentUser._id;
      }
      filters.country = await this.warhouseSelected;

      const res = await this.$server.search("payments", filters);
      this.loading = false;
      if (res.content.results) {
        this.payements = res.content.results;
        this.payements.forEach((arg) => {
          arg.count = arg.count + " Orders";
          if (arg.amountCurrency && arg.amountCurrency.length > 0) {
            arg.amountCurrency = this.sumObjAmountCurrency(arg.amountCurrency);
            arg.amount = "";
            arg.amountCurrency.forEach((price, j) => {
              arg.amount += `${price.amount.toFixed(2)} <sup>${
                price.currency
              }</sup>`;
              if (j != arg.amountCurrency.length - 1) arg.amount += ` | `;
            });
          } else
            arg.amount =
              arg.amount.toFixed(2) +
              " <sup>" +
              this.idWharhouse.currency +
              "</sup>";
        });
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.payements = [];
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },
    async search(data) {
      const search = {
        name: data.trim(),
      };
      if (this.currentUser.type == "Seller") {
        search.seller = this.currentUser._id;
      }
      search.country = await this.warhouseSelected;
      const res = await this.$server.find("payments", search);
      if (res.content.results) {
        this.payements = res.content.results;
        this.payements.forEach((arg) => {
          arg.count = arg.count + " Orders";
          if (arg.amountCurrency && arg.amountCurrency.length > 0) {
            arg.amountCurrency = this.sumObjAmountCurrency(arg.amountCurrency);
            arg.amount = "";
            arg.amountCurrency.forEach((price, j) => {
              arg.amount += `${price.amount.toFixed(2)} <sup>${
                price.currency
              }</sup>`;
              if (j != arg.amountCurrency.length - 1) arg.amount += ` | `;
            });
          } else
            arg.amount =
              arg.amount.toFixed(2) +
              " <sup>" +
              this.idWharhouse.currency +
              "</sup>";
        });
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.payements = [];
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },
    async remove(id) {
        this.$swal.fire({
            title: this.$t("confirm_delete_payment"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (result) => {
            if (result.isConfirmed) {
          const resp = await this.$server.delete("payments", { id: id });
          await this.refresh();
          alert(this.$t("delete_payment"), "success");
        }
      });
    },
    /********************************************************************************************/
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      const filters = {
        page: page,
        limit: this.limit,
      };
      this.getPayement(filters);
    },
  },
};
</script>

<style>
/* animation for vue transition tag */
dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}
dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/**********************************Table Product PopUp ***************************/

@media (min-width: 640px) {
  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
</style>
