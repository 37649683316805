var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-1"
  }, [_c('div-table', {
    attrs: {
      "config": _vm.configList,
      "data": _vm.payements,
      "element": _vm.element,
      "loading": _vm.loading,
      "paginate": _vm.paginate,
      "idWharhouse": _vm.idWharhouse,
      "currentUser": _vm.currentUser,
      "tabId": _vm.tabId
    },
    on: {
      "reset": _vm.reset,
      "refresh": _vm.refresh,
      "showProducts": _vm.showOrders,
      "filtrer": _vm.filtrer,
      "searchSellers": _vm.searchSellers,
      "search": _vm.search,
      "paginatation": _vm.paginatation,
      "selectAllCheckbox": _vm.selectAllCheckbox,
      "getRowDetail": _vm.getRowDetail
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }